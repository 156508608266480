<template>
    <div class="flex max-lg:flex-col bg-white rounded px-6 py-4 items-center lg:gap-8 justify-between mb-8">
        <p class="text-3xl font-bold text-black mb-5 leading-[1.4]">
            Done-for-you Setup
            <br>
            <span class="font-light">
            $299
            </span>
        </p>

        <table class="text-left align-middle">
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    We'll do a one-time account setup for you, tailored to your needs.
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    Contact us for Details.
                </td>
            </tr>
        </table>

        <div class="mt-1 lg:mt-3 py-4 lg:py-8 flex items-center justify-center gap-x-6">
            <SecondaryButton
                @click="sendEmail"
                class="scale-125 lg:scale-150 lg:origin-right">
                Contact Us
            </SecondaryButton>
        </div>
    </div>

</template>
<script setup lang="ts">
import logoBlack from "../../../../images/logo-black.svg";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index";

const sendEmail = () => {
    const email = String.fromCharCode(
        115, 117, 112, 112, 111, 114, 116, 64,
        103, 114, 101, 101, 116, 109, 97, 116, 101,
        46, 97, 105
    );

    window.open(`mailto:${email}`);
};

</script>
